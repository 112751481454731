<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :search.sync="search"
                 v-model="form">
        <template slot="menu"
                  slot-scope="{row,index,size}">
          <el-button icon="el-icon-setting"
                     type="text"
                     :size="size"
                     @click="handlePermission(row,index)">设置权限</el-button>
        </template>
      </avue-crud>
    </el-card>
    <el-dialog class="avue-dialog"
               append-to-body
               destroy-on-close
               title="设置权限"
               :visible.sync="box"
               width="40%">
      <el-tree :data="treeList"
               show-checkbox
               :before-close="handleClose"
               :default-checked-keys="treeCheck"
               node-key="id"
               @check="handleCheckChange">
      </el-tree>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="box = false">取 消</el-button>
        <el-button type="primary"
                   @click="setPermission">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { bind, list, } from '@/api/sys/permission'
import { detail } from '@/api/sys/role'
export default window.$crudCommon({
  data () {
    return {
      box: false,
      treeForm: {},
      treeCheck: [],
      treeList: [],
      treeData: []
    }
  },
  created () {
    this.getPermission()
  },
  methods: {
    handleClose (done) {
      this.treeData = []
      this.treeCheck = []
      done()
    },
    getPermission () {
      list({ size: 1000 }).then(res => {
        let list = res.data.records || []
        list = list.map(ele => {
          return Object.assign(ele, {
            label: `${ele.name}[${ele.code}]`
          })
        })
        this.treeList = list
      })
    },
    handleCheckChange (checkedNodes, checkedKeys) {
      this.treeData = checkedKeys.checkedKeys
    },
    setPermission () {
      bind(this.treeForm.id, this.treeData).then(() => {
        this.$message.success('权限设置成功')
        this.treeData = []
        this.treeCheck = []
        this.box = false
      })

    },
    handlePermission (row) {
      detail(row.id).then(res => {
        let data = (res.data.permissions || []).map(ele => ele.id)
        this.treeCheck = data;
        this.box = true
        this.treeForm = row;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/sys/role',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>